
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.whyUsWrapper {
    max-width: 1460px;
    width: 100%;
    position: relative;
    & > div {
        width: 50%;
        &:last-child {
            svg {
                width: 100%;
                height: auto;
            }
        }
        @media screen and (max-width: 1023px) {
            width: auto;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 1023px) {
        text-align: center;
    }
}
.mainTitle {
    font-size: 36px;
    line-height: 1.2;
    @media screen and (max-width: 1023px) {
        font-size: 32px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
    }
    @media screen and (max-width: 414px) {
        font-size: 24px;
    }
}
.whyUsImage {
    max-width: 668px;
    svg {
        position: relative;
        left: -125px;
        @media screen and (max-width: 1023px) {
            left: -10px;
            width: 70% !important;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 1023px) {
        margin-bottom: 50px !important;
    }
}
.icon {
    min-width: 100px;
    @media screen and (max-width: 1023px) {
        width: 60px;
        min-width: inherit;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    svg {
        width: 100%;
        @media screen and (max-width: 1023px) {
            margin: 0 auto 20px;
        }
    }
}
